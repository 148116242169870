import { green, purple } from '@mui/material/colors';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1E1819',
      contrastText: '#fdffff',
    },
    secondary: {
      main: '#ff0008',
    },
    background: {
      default: '#FDFFFF',
      paper: '#fdffff',
    },
    info: {
      main: '#121a40',
    },
    success: {
      main: '#121a40',
    },
  },
  typography: {
    fontFamily: 'Yanone Kaffeesatz',
    fontWeightLight: 400,
    fontWeightMedium: 400,
    fontWeightBold: 400,
    body1: {
      fontWeight: 300,
      fontSize: '1.7rem',
    },
  },
});
export default theme;
