import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import CustomThemeProvider from './themes/CustomThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';



ReactDOM.render(
  <React.StrictMode>
    <CustomThemeProvider>
      <CssBaseline>
        <App />
      </CssBaseline>
    </CustomThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
