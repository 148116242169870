import React from 'react';
import { Box, Typography, Toolbar,IconButton, AppBar, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const goVotePage = () => {
  window.location.href = "https://www.lagamecup.com/presentation-des-projets-boss/";
}
const goSteamPage = () => {
  window.location.href = "https://www.lagamecup.com/presentation-des-projets-boss/";
}

function WillView() {
  return (
  <>
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Will.exe
        </Typography>
        <Button color="inherit" onClick={goVotePage}>Votez pour nous</Button>
        <Button color="inherit" onClick={goSteamPage}>Steam</Button>
      </Toolbar>
    </AppBar>

    <Box id="parallax-container" 
    sx={{display:'block', backgroundSize: "cover !important", 
        position:"fixed", top:0, backgroundPosition:"center !important",
        
        }} 
    height="900px"
    width="100%">
      <div sx={{background: "./Images/Will/Logo-La-Game-Cup-4.png"}}/>
      <div sx={{background: "./Images/Will/Logo-La-Game-Cup-4.png"}}/>
      <div sx={{background: "./Images/Will/Logo-La-Game-Cup-4.png"}}/>
      <div sx={{background: "./Images/Will/Logo-La-Game-Cup-4.png"}}/>
      <div sx={{background: "./Images/Will/Logo-La-Game-Cup-4.png"}}/>
    </Box>

    <div id="maincontain">
      <div id="main">
				<div className="section buy">
					<h2 className="banner nobackground"><em>Available now for</em> $19.99</h2>
				</div>
				<div className="section internallinks gridwithicons">
					<ul className="links smalllinks">
						<li className="link media">
              <a href="http://store.steampowered.com/app/383870">
							  <h4 className="banner">
                  <span className="twolines">
                    <Box component="img" src="images/buyicon_pc.png"/>
                  </span>
                </h4>
						  </a>
            </li>
						<li className="link faq">
            <a href="https://store.playstation.com/en-us/search/firewatch">
							<h4 className="banner">
              <span className="oneline"><Box component="img" src="images/buyicon_ps4.png"/>
              </span>
              </h4>
						</a>
            </li>
					</ul>
					<ul className="links smalllinks">
						<li className="link media">
              <a href="https://www.nintendo.com/games/detail/firewatch-switch">
                <h4 className="banner">
                  <span className="twolines">
                    <Box component="img" src="images/buyicon_switch.png"/>
                  </span>
                </h4>
              </a>
            </li>
						<li className="link faq">
              <a href="https://www.microsoft.com/store/p/firewatch/bqqkg9h2stc0">
                <h4 className="banner">
                  <span className="oneline">
                    <Box component="img" src="images/buyicon_xbone.png"/>
                  </span>
                </h4>
              </a>
            </li>
					</ul>
				</div>				

				<div className="section feature videoparent">
					<div className="videocontain">
						<iframe src="https://www.youtube-nocookie.com/embed/cXWlgP5hZzc" frameBorder="0" allowFullScreen></iframe>
					</div>
				</div>

				<div className="section description">
					<h5>Firewatch is a mystery set in the Wyoming wilderness, where your only emotional lifeline is the person on the other end of a handheld radio.</h5>
					<div className="columntext">
						<p className="left">The year is 1989.
						<br /><br />
						You are a man named Henry who has retreated from your messy life to work as a fire lookout in the Wyoming wilderness. Perched atop a mountain, it's your job to find smoke and keep the wilderness safe.
						<br /><br />
						An especially hot, dry summer has everyone on edge. Your supervisor, a woman named Delilah, is available to you </p><p>at all times over a small, handheld radio—and is your only contact with the world you've left behind.
						<br /><br />
						But when something strange draws you out of your lookout tower and into the world below, you'll explore a wild and unknown environment, facing questions and making interpersonal choices that can build or destroy the only meaningful relationship you have.
						</p>
					</div>
				</div>

				<div className="section internallinks">
					<ul className="links smalllinks dim">
						<li className="link media"><a href="media">
							<h4 className="banner"><span>Screens &amp; Trailers</span></h4>
						</a></li>

					</ul>
					<ul className="links smalllinks dim">

						<li className="link faq"><a href="about">
							<h4 className="banner"><span>Firewatch FAQ</span></h4>
						</a></li>
					</ul>
				</div>

				<div className="section screenshots">
					<ul className="thumbnails">
						<li>
							<a href="screenshots/firewatch-e3-2.jpg"><Box component="img" alt="Firewatch screenshot" src="screenshots/thumbs/firewatch_01.jpg" /></a>
						</li>
            <li>
							<a href="screenshots/firewatch_150305_06.png"><Box component="img" alt="Firewatch screenshot" src="screenshots/thumbs/firewatch_02.jpg" /></a>
						</li>
            <li>
							<a href="screenshots/firewatch-2.jpg"><Box component="img" alt="Firewatch screenshot" src="screenshots/thumbs/firewatch_03.jpg" /></a>
						</li>
            <li>
							<a href="screenshots/firewatch-e3-5.jpg"><Box component="img" alt="Firewatch screenshot" src="screenshots/thumbs/firewatch_04.jpg" /></a>
						</li>
					</ul>
				</div>

				<div className="section pressquote big">
					<h2>"As visually striking <br />as its unique premise."</h2>
					<h3>Entertainment Weekly</h3>
				</div>

				<div className="section support">
					<ul className="supportlinks smalllinks dim">
						<li className="link techsupport"><a href="https://library.panic.com/firewatch/">
							<h4 className="banner"><span>Tech Support</span></h4>
						</a></li>
						<li className="link streaming"><a href="about#streaming">
							<h4 className="banner"><span>Streaming &amp; Let's Plays</span></h4>
						</a></li>
					</ul>
				</div>

				<div className="section copyright">
					<p>&copy;<span id="year">2016</span> Campo Santo, in cooperation with Panic.</p>
					<p>Firewatch is a trademark of Campo Santo.</p>
					<p>Nintendo Switch is a trademark of Nintendo.</p>
				</div>

				<div className="section companylinks">
					<ul className="companies">
						<li className="campo"><a href="http://www.camposanto.com/">
							<h1>Campo Santo</h1>
						</a></li>
						<li className="panic"><a href="http://www.panic.com">
							<h1>Panic</h1>
						</a></li>
					</ul>
				</div>

			</div>
      </div>
    </>
  );
}

export default WillView;
