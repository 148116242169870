import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ProjectsMasonry from './ProjectsMasonry';
import BriefView from './BriefView';
import TeamView from './TeamView';
import CtaView from './CtaView';
import LandingView from './LandingView';
import AppFooter from '../components/layout/footer';
import { padding } from '@mui/system';
import BannerView from './BannerView';

const styles = {
  logo: {
    display: 'block',
    //borderRadius: '50%',
    //animation: 'pulse 2s infinite',
  },
};

function HomeView() {
  return (
    <Grid container justifyContent="center" alignItems="center" paddingTop="70px">
      <Grid item xs height="100%">
        <BannerView/>
        <LandingView />
        <BriefView />
        <ProjectsMasonry />
        <TeamView />
        <CtaView />
        <AppFooter />
      </Grid>
    </Grid>
  );
}

export default HomeView;
