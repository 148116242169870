import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Typography, Button } from '@mui/material';
import { AnalyticEvent } from '../AnalyticsEvent';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const handleRedirect = (url,target = '_self' ,label ) => {
  window.open(url, target);
  AnalyticEvent ('Link', url, label);
};

const goKissKissPage = () => {
  handleRedirect('https://www.kisskissbankbank.com/fr/projects/will-exe', '_blank','KissKissWill') ;
};

const goSteamPageWill = () => {

  handleRedirect('https://store.steampowered.com/app/2504580/Willexe/', '_blank');
};

function BannerView() {
  return (
    <Box
      component="section"
      className="sectionTransparent"
      sx={{ display: 'flex', overflow: 'hidden' }}
    >
      <Container sx={{ mt: 25, mb: 40, display: 'flex', position: 'relative' }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box sx={item}>
              <Box
                component="img"
                src="./Images/Will/Logo_KissKiss_Will.png"
                alt="Scissors In The Plug Logo"
                sx={{ width: 300, height: 300, borderRadius: '10%', marginLeft: '90px' }}
              />
              <Typography variant="h4" sx={{ my: 5, height: 75 }} textAlign="center">
                Aidez à financer l'émancipation d'un personnage de jeu vidéo !
              </Typography>
              <Button
                component="a"
                color="primary"
                variant="contained"
                sx={{ width: '50%', fontSize: '1.5rem' }}
                onClick={goKissKissPage}
              >
                Soutenir le projet
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={item}>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ height: 300 }}
              >
                <Grid item>
                  <Box
                    component="img"
                    src="./Images/Will/Logo_Steam_Will.png"
                    alt="Kapsloc Logo"
                    sx={{ width: 300 }}
                  />
                </Grid>
              </Grid>
              <Typography variant="h4" sx={{ my: 5, height: 75 }} textAlign="center">
                Un jeu dont le personnage ne veut plus être contrôlé. Plus jamais.
              </Typography>
              <Button
                component="a"
                color="primary"
                variant="contained"
                sx={{ width: '50%', fontSize: '1.5rem' }}
                onClick={goSteamPageWill}
              >
                Découvrir sur Steam
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default BannerView;