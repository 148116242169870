import React from 'react';
import { HashRouter as Router, Routes, Route, Outlet} from 'react-router-dom';
import { Grid } from '@mui/material';
import Header from './components/layout/navigation';
import MentionsView from './pages/MentionsView';
import HomeView from './pages/HomeView';
import Seo from './components/Seo';
import WillView from './pages/WillView';
import ReactGA from 'react-ga4';
import {  } from 'react-router-dom';

ReactGA.initialize('G-MFE43G00HN') ;


const AppLayout = () => (
     <div className="background">
        <Seo
          title="Scissors In The Plug"
          keywords="Video Games, Scissors In The Plug, indie, Developpement, Edition, Collaboration, Kapsloc, Bordeaux, France"
          description="Scissors In The Plug est un studio français de jeux vidéo basé à Bordeaux."
        />
          <Header />
          <Grid
            container
            spacing={0}
            minHeight="100vh"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
          <Outlet />
          </Grid>
        </div>
  
);


function App() {

  return (
    <>
      <Router>
            <Routes>
              <Route path="/will" element={<WillView />} />
              <Route element={<AppLayout />}>
                <Route path="/" element={<HomeView />} />
                <Route path="/about" element={<MentionsView />} />
                <Route path="/will" element={<WillView />} />
              </Route>
            </Routes>
      </Router>
    </>
  );
}

export default App;

