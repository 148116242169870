import React, { useState } from 'react';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import getTheme from './switch';

export const CustomThemeContext = React.createContext({
  currentTheme: 'light',
  setTheme: null,
  getTheme: null,
});

const CustomThemeProvider = (props) => {
  const { children } = props;

  const currentTheme = localStorage.getItem('appTheme') || 'light';

  const [themeName, _setThemeName] = useState(currentTheme);

  const theme = responsiveFontSizes(getTheme(themeName));

  const setThemeName = (name) => {
    localStorage.setItem('appTheme', name);
    _setThemeName(name);
  };

  const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName,
    getTheme: theme,
  };

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;
