import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link as LinkRouter } from 'react-router-dom';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mr: 1,
};

export default function AppFooter() {
  return (
    <Box
      component="section"
      className="sectionTransparent"
      sx={{ display: 'flex', overflow: 'hidden' }}
    >
      <Container sx={{ display: 'flex' }}>
        <Grid container direction="row" justifyContent="flex-start" spacing={2} sx={{}}>
          <Grid item sx={{ display: 'flex' }} alignItems="center" justifyContent="center">
            <Box
              component="a"
              href="https://www.instagram.com/scissorsintheplug_studio/"
              sx={iconStyle}
            >
              <img
                src="/Images/instagram.png"
                alt="Instagram"
                style={{
                  width: 48,
                }}
              />
            </Box>
            <Box
              component="a"
              href="https://fr.linkedin.com/company/scissors-in-the-plug"
              sx={iconStyle}
            >
              <img
                src="/Images/linkedin.png"
                alt="Linkedin"
                style={{
                  width: 48,
                }}
              />
            </Box>
            <Box href="https://fr.linkedin.com/company/scissors-in-the-plug">
              <Link sx={{ color: 'white', fontSize: 24 }} href="https://scissorsintheplug.com/">
                © Scissors In The Plug {new Date().getFullYear()}
              </Link>
            </Box>
          </Grid>
          <Grid item>
            <Button
              component={LinkRouter}
              to="/about"
              key="About"
              sx={{ color: 'white', fontSize: 24 }}
            >
              Mentions légales
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
