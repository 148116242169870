import React from 'react';
import { Box, Typography, Container } from '@mui/material';

function MentionsView() {
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography className="glitch" data-text="MENTIONS LEGALES" variant="h1">
        MENTIONS LEGALES
      </Typography>
      <Container>
        <br />
        <Typography variant="body1">
          Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en
          l'économie numérique, il est précisé aux utilisateurs du site Scissors In The Plug
          l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
        </Typography>
        <br />
        <Typography variant="h3">Edition du site</Typography>
        <br />
        <Typography variant="body1">
          Le présent site, accessible à l’URL www.scissorsintheplug.com (le « Site »), est édité par
          : Scissors In The Plug, société au capital de 60 euros, inscrite au R.C.S. de LIBOURNE
          sous le numéro 893 609 560 R.C.S. Libourne, dont le siège social est situé au 6 rue du
          taillis 33620 CAVIGNAC, représenté(e) par Benjamin COUDANNES dûment habilité(e) Le numéro
          individuel TVA de l’éditeur est : FR19893609560.
        </Typography>
        <br />
        <Typography variant="h3">Hébergement</Typography>
        <br />
        <Typography variant="body1">
          Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053
          Roubaix Cedex 1, (contact téléphonique ou email : 1007).
        </Typography>
        <br />
        <Typography variant="h3">Directeur de publication</Typography>
        <br />
        <Typography variant="body1">
          Le Directeur de la publication du Site est Benjamin COUDANNES .
        </Typography>
        <br />
        <Typography variant="h3">Nous contacter</Typography>
        <br />
        <Typography variant="body1">
          Par téléphone : +33669492188 Par email : technique@scissorsintheplug.com Par courrier : 6
          rue du taillis 33620 CAVIGNAC
        </Typography>
      </Container>
    </Box>
  );
}

export default MentionsView;
