import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import MuiToolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { Avatar, Typography } from '@mui/material';

const Header = () => {
  const Toolbar = styled(MuiToolbar)(({ theme }) => ({
    height: 64,
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
  }));

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <AppBar position="fixed">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ flex: 1 }} />
        <Typography variant="h3" sx={{ my: 2, color: 'white', display: 'block' }}>
          Scissors In The Plug
        </Typography>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            component={Link}
            to="/"
            key="Home"
            sx={{ my: 2, color: 'white', display: 'block', fontSize: 24 }}
            onClick = {scrollToTop}
          >
            Home
          </Button>
          <Button
            component={Link}
            to="/about"
            key="About"
            sx={{ my: 2, color: 'white', display: 'block', fontSize: 24 }}
          >
            About
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
