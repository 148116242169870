import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import ReactGA from 'react-ga';
import { AnalyticEvent } from '../AnalyticsEvent';
import { Typography, Button, Snackbar, TextField } from '@mui/material';

function CtaView() {
  const [open, setOpen] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailRedirect = () => {
    AnalyticEvent ('Email', "mailto:technique@scissorsintheplug.com");
    window.location.href = "mailto:technique@scissorsintheplug.com"
  };

  return (
    <Container component="section" sx={{ display: 'flex', overflow: 'hidden', mt: 10 }}>
      <Grid container style={{ minHeight: '70vh' }}>
        <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 8,
              px: 3,
            }}
          >
            <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400 }}>
              <Typography variant="h2" component="h2" gutterBottom>
                Contact Us
              </Typography>
              <Typography variant="h5" gutterBottom>
                Don't hesitate to discuss about your projects, video games or anything else with us
                !
              </Typography>
              <Typography variant="h5" gutterBottom>
                technique@scissorsintheplug.com
              </Typography>
              <Button
                onClick = {handleEmailRedirect}
                component="a"
                color="primary"
                variant="contained"
                sx={{ width: '50%', fontSize: '2rem'}}
              >
                Contact US
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -67,
              left: -67,
              right: 0,
              bottom: 0,
              width: '100%',
            }}
          />
          <Box
            component="img"
            src="./Logo.png"
            alt="Scissors In The Plug Logo"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '80%',
              maxWidth: 600,
            }}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        closeFunc={handleClose}
        message="We will send you our best offers, once a week."
      />
    </Container>
  );
}

export default CtaView;
