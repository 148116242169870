import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography, Grid, Button } from '@mui/material';

export default function LandingView() {
  return (
    <Container
      component="section"
      sx={{
        mt: 8,
        mb: 4,
      }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item textAlign="center" justifyContent="center">
          <Box
            component="img"
            src="./Logo.png"
            alt="Scissors In The Plug Logo"
            sx={{ height: 300 }}
          />
          <Typography variant="h2" marked="center" align="center" component="h2">
            We make video games.
          </Typography>
          <Typography variant="h5" marked="center" align="center" component="h2">
            French video games studio in Bordeaux.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
